
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    Request: () =>
      import("@/app/modules/vacation/components/RequestComponent.vue")
  }
})
export default class VacationRequestsPage extends Vue {
  get vacationRequestsData() {
    return this.$store.getters["vacation/data"];
  }

  created() {
    this.$store.dispatch("vacation/fetch").then(({ data }) => {
      this.$store.commit("vacation/setData", data.data);
    });
  }
}
